@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@300;500;800&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  --crimsonRed: #950000;
  --darkSilver: #707070;
  --blackColor: #000000;
  --whiteColor: #ffffff;
  font-size: 10px;
}
input:focus,
textarea:focus {
  outline: none;
  box-shadow: none;
}

body,
button {
  font-family: "Nunito", sans-serif;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 !important;
}
p {
  margin: 0 !important;
}
.p-0 {
  padding: 0 !important;
}
.absoluteLink {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  cursor: pointer;
}
.img-fluid {
  width: 100%;
  height: auto;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.container {
  width: 90%;
  margin: auto;
}
.row {
  display: flex;
  flex-wrap: wrap;
}
.col-md-6 {
  width: 50%;
  padding: 0 1.5rem;
}
.col-md-5 {
  width: 41.66%;
  padding: 0 1.5rem;
}
.col-md-7 {
  width: 58.33%;
  padding: 0 1.5rem;
}
.col-md-4 {
  width: 33.33%;
  padding: 0 1.5rem;
}

.col-md-3 {
  width: 25%;
  padding: 0 1.5rem;
}
.col-md-2 {
  width: 16.66%;
  padding: 0 1.5rem;
}
.col-md-8 {
  width: 66.67%;
  padding: 0 1.5rem;
}
.col-md-10 {
  width: 83.33%;
  padding: 0 1.5rem;
}
.col-md-12 {
  width: 100%;
  padding: 0 1.5rem;
}
.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}
.mt-5 {
  margin-top: 5rem;
}
.d-flex {
  display: flex !important;
}
.justify-content-center {
  justify-content: center !important;
}
.align-items-center {
  align-items: center !important;
}

.sectionPadding {
  padding: 5rem 0;
  position: relative;
}

.section-80vh {
  min-height: 80vh;
}
.headingWrapper {
  width: 50%;
  margin: 0 auto 5rem auto;
}
.mainHeading {
  position: relative;
  font-size: 3.5rem;
  text-align: center;
  font-weight: 800;
}
.mainHeading::before {
  position: absolute;
  content: "";
  bottom: -1rem;
  left: 50%;
  width: 10rem;
  height: 0.5rem;
  border-radius: 1rem;
  background-color: var(--crimsonRed);
  transform: translateX(-50%);
}
.mainSubheading {
  font-size: 1.5rem;
  text-align: center;
  font-weight: 400;
  margin-top: 2.5rem !important;
}
.text-right {
  text-align: right;
}
.formLabel {
  display: block;
  font-size: 1.5rem;
  margin-bottom: 1rem !important;
}
.formControl {
  display: block;
  padding: 1rem;
  width: 100%;
  border: 0.1rem solid var(--darkSilver);
  border-radius: 0.4rem;
  font-size: 1.5rem;
  color: var(--blackColor);
}
.error {
  border-color: var(--crimsonRed);
  background-color: #ffeeee;
}
.textarea {
  display: block;
  padding: 1rem;
  width: 100%;
  border: 0.1rem solid var(--darkSilver);
  border-radius: 0.4rem;
  font-size: 1.5rem;
  color: var(--blackColor);
}
/* ------------------ ------------------ ------------------ */
.topHeader {
  padding: 2rem 0;
}
.navbar,
.linkWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.logoContainer img {
  width: 20rem;
  height: auto;
}
.linkWrapper .nav-item {
  list-style: none;
}
.linkWrapper .nav-item .nav-link {
  text-decoration: none;
  display: inline-block;
  padding: 1rem 2rem;
  font-size: 1.6rem;
  color: var(--darkSilver);
  transition: 0.3se ease-in-out;
}
.linkWrapper .nav-item .nav-link:hover {
  color: var(--crimsonRed);
}
/* --------------------------- */

.leftCircle {
  width: 15rem;
  height: 15rem;
  border-radius: 15rem;
  background-color: var(--crimsonRed);
  position: absolute;
  bottom: -4rem;
  left: -7.5rem;
  opacity: 0.1;
}
.bannerContent h2 {
  font-size: 4.5rem;
  font-weight: 500;
  margin-bottom: 1rem;
}
.bannerContent h2 strong {
  font-size: 4.5rem;
  font-weight: 800;
}
.bannerContent p {
  font-size: 1.6rem;
  margin-top: 2rem;
}
.bannerImg {
  text-align: right;
}
.bannerImg img {
  width: 80%;
  height: auto;
  margin-left: auto;
}
.bannerBtns {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 2rem;
  margin-top: 3rem;
}
.bannerBtns a {
  text-decoration: none;
  color: var(--darkSilver);
  font-size: 1.4rem;
  font-weight: 600;
  text-transform: uppercase;
  padding: 1.4rem 3rem;
  border-radius: 0.6rem;
  transition: 0.3s ease-in-out;
  color: var(--crimsonRed);
}
.btnRegister {
  background-color: var(--crimsonRed);
  color: var(--whiteColor) !important;
}
.btnRegister:hover {
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.3);
  transform: translateY(-0.2rem);
}

.sponsorImgs {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.sponsorImgs img {
  width: 100%;
  height: auto;
}
.heroBtn2:hover {
  background-color: #feeaea;
  color: var(--crimsonRed);
  transform: translateY(-0.2rem);
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.1);
}
/* ---------------------- */
.singleChooseCard {
  padding: 2rem;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.143);
  transition: 0.3s ease-in-out;
  height: 36.7rem;
}
.singleChooseCard.active {
  background-color: var(--crimsonRed);
}
.singleChooseCard.active h4,
.singleChooseCard.active p {
  color: var(--whiteColor);
}
.singleChooseCard img {
  width: 6rem;
  height: 6rem;
  margin-bottom: 2rem;
}
.singleChooseCard h4 {
  font-size: 1.8rem;
  font-weight: 700;
  color: var(--blackColor);
  margin-bottom: 1rem !important;
}
.singleChooseCard p {
  font-size: 1.4rem;
  color: var(--darkSilver);
}
.singleChooseCard svg {
  width: 8rem;
  height: 8rem;
}
.singleChooseCard:hover .lastSvg svg path:nth-child(even) {
  fill: var(--whiteColor);
}
.singleChooseCard:hover .lastSvg svg path:nth-child(odd) {
  fill: var(--crimsonRed);
}
.singleChooseCard:hover {
  background-color: var(--crimsonRed);
}
.singleChooseCard:hover h4,
.singleChooseCard:hover p {
  color: var(--whiteColor);
}
.singleChooseCard:hover svg path {
  fill: var(--whiteColor);
}
.singleChooseCard:hover svg rect {
  fill: var(--whiteColor);
}

.singleChooseCard.colorSvg svg path {
  fill: var(--crimsonRed);
}
.singleChooseCard.colorSvg svg rect {
  fill: #ffcdd5;
}
.singleChooseCard.colorSvg:hover svg path {
  fill: var(--whiteColor);
}
/* ----------------------------- */
.howSection {
  position: relative;
  background-image: url("./images//howSection.svg");
  background-size: cover;
  background-position: center;
  padding: 10rem 0;
  margin-top: 5rem;
}
.howSection .bannerLeft {
  position: absolute;
  left: 0;
  top: 5rem;
  width: 46%;
  height: auto;
}
.howContent h4 {
  position: relative;
  font-size: 3.5rem;
  margin-bottom: 2rem !important;
  color: var(--whiteColor);
}
.howContent h4::before {
  position: absolute;
  content: "";
  bottom: -1rem;
  left: 0;
  width: 8rem;
  height: 0.3rem;
  background-color: var(--whiteColor);
  border-radius: 2rem;
}
.howContent p {
  font-size: 1.6rem;
  font-weight: 400;
  color: var(--whiteColor);
}
.pointContainer {
  display: flex;
  margin-top: 3.5rem;
}
.pointContainer img {
  width: 6rem;
  height: 6rem;
  margin-right: 1.4rem;
  border-radius: 5rem;
}
.pointContainer .pointTxt h6 {
  font-size: 2rem;
  font-weight: 600;
  color: var(--whiteColor);
}
.pointContainer .pointTxt p {
  font-size: 1.4rem;
  color: var(--whiteColor);
}
/* ------------------------------- */
.discoverPicture {
  text-align: center;
}
.discoverPicture img {
  width: 100%;
  height: auto;
}
.discoverContent {
  display: flex;
  margin-bottom: 5rem;
  column-gap: 1rem;
}
.discoverContent img {
  width: 4rem;
  height: 4rem;
}
.discoverContent h4 {
  font-size: 1.8rem;
  font-weight: 700;
  margin-bottom: 1rem;
  margin-bottom: 0.3rem !important;
}
.discoverContent p {
  font-size: 1.4rem;
  font-weight: 400;
}

/* ---------------------- */
.singleBlogCard {
  position: relative;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  margin-bottom: 5rem;
}
.singleBlogCard img.blogImg {
  width: 100%;
  height: 24rem;
  object-fit: cover;
}
.blogContent {
  padding: 2.5rem;
}
.blogInfo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 1rem;
  margin-bottom: 1rem;
}
.blogInfo span {
  font-size: 1.2rem;
  color: var(--darkSilver);
  display: flex;
  align-items: center;
}
.blogInfo span img {
  width: 2rem;
  height: 2rem;
  margin-right: 1rem;
}
.singleBlogCard h4 {
  font-size: 1.9rem;
  font-weight: 700;
}
.singleBlogCard p {
  font-size: 1.5rem;
  color: var(--darkSilver);
  margin: 1rem 0 !important;
}

.singleBlogCard .readMore {
  font-size: 1.6rem;
  font-weight: 800;
  cursor: pointer;
}

.sliderBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 1rem;
  margin-top: -3rem;
}
.testimonialSliderBtn {
  margin-top: 0;
}
.sliderBtn button {
  border: none;
  background: transparent;
  cursor: pointer;
  padding: 2rem;
}
.sliderBtn button img {
  width: 3rem;
}
.appSection {
  position: relative;
  padding: 6.5rem 0;
  background-color: var(--crimsonRed);
}
.appSection::before {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  height: 100%;
  width: 20%;
  background: url("./images//preRegisterBg.svg");
  background-size: cover;
  background-position: right;
}
.appSection .appBanner h4 {
  font-size: 3.5rem;
  color: var(--whiteColor);
}
.appSection .appBanner p {
  font-size: 1.5rem;
  color: var(--whiteColor);
  margin: 3rem 0 !important;
}
.appSection .appBanner button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5rem;
  border: none;
  padding: 1.4rem 2rem;
  background-color: rgba(255, 255, 255, 0.3);
  cursor: pointer;
}

.appSection .appBanner button img {
  width: 5rem;
  height: auto;
  margin-right: 1rem;
}
.appSection .appBanner button h5 {
  text-align: left;
  color: var(--whiteColor);
  font-size: 1.4rem;
  font-weight: 400;
}
.appSection .appBanner button h5 span {
  display: block;
  text-align: left;
  font-size: 1.6rem;
  font-weight: 700;
}

.appSection .appPicture {
  position: relative;
  width: 100%;
  height: 30rem;
  text-align: right;
}
.appSection .appPicture img {
  position: relative;
  width: 80%;
  top: -20rem;
  z-index: 88;
}
.footerLeft {
  margin-bottom: 5rem;
}
.footerLeft p {
  font-size: 1.5rem;
  font-weight: 300;
  margin: 1.5rem 0 !important;
  text-align: left;
}
.footerLeft p strong {
  font-size: 1.3rem;
  line-height: 22px;
}
.footerLeft ul.socialIcons {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 1rem;
  list-style: none;
}

.footerLeft ul.socialIcons a svg {
  font-size: 1.8rem;
  color: var(--crimsonRed);
}
.copywrite {
  background-color: var(--crimsonRed);
  text-align: center;
  font-size: 1.6rem;
  font-weight: 700;
  position: absolute;
  bottom: 0;
  width: 100%;
  color: var(--whiteColor);
  padding: 1.8rem 0;
}
.linkeContainer {
  width: 60%;
  margin-left: auto;
}
.linkeContainer ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0;
  list-style: none;
  margin-bottom: 3rem;
}
.linkeContainer h4 {
  font-size: 2rem;
  margin-bottom: 1.5rem !important;
  color: var(--crimsonRed);
}
.linkeContainer ul li a {
  font-size: 1.6rem;
  text-decoration: none;
  color: var(--darkSilver);
}
.linkeContainer ul li a:hover {
  color: var(--crimsonRed);
}

.testimonialSection {
  position: relative;
  background-color: rgb(255, 92, 115, 9%);
  /* min-height: 100vh; */
  padding-bottom: 20rem;
  /* display: none; */
}
.testimonialSection::after {
  position: absolute;
  content: "";
  background: url("./images/testimonialBanner.svg");
  background-size: cover;
  z-index: 1;
  width: 100%;
  height: 62%;
  bottom: 0;
  left: 0;
}
.testimonialSection .container {
  position: relative;
  z-index: 99;
}

.testimonialCard {
  position: relative;
  background-color: var(--whiteColor);
  padding: 3rem;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  padding-top: 7rem;
  margin-bottom: 1rem;
  margin-top: 5rem;
  z-index: 2;
  opacity: 0.4;
  border-radius: 0.5rem;
}
.swiper-slide-active .testimonialCard {
  opacity: 1;
  border-bottom: 0.3rem solid var(--crimsonRed);
}
.testimonialCard .profilePic {
  position: absolute;
  top: -5rem;
  width: 12rem;
  height: 12rem;
  object-fit: cover;
  margin: auto;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
}
.testimonialCard .profilePic img {
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.testimonialCard h4 {
  font-size: 2rem;
  text-align: center;
}

.testimonialCard span {
  font-size: 1.6rem;
  text-align: center;
  color: var(--crimsonRed);
  font-weight: 700;
  display: block;
  margin: 0.5rem 0;
}
.testimonialCard p {
  font-size: 1.4rem;
  text-align: center;
  color: var(--darkSilver);
}
.mobileMenu,
.sideOverlay {
  display: none;
}
.nav-link.registerBtn {
  background-color: var(--crimsonRed);
  color: var(--whiteColor) !important;
  text-decoration: none;
  color: var(--darkSilver);
  font-size: 1.4rem;
  font-weight: 600;
  padding: 1.4rem 3rem;
  border-radius: 0.6rem;
  transition: 0.3s ease-in-out;
}
.nav-link.registerBtn:hover {
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.3);
}

/* ----------------------------------- */
.appbreadcrumb {
  background-color: var(--crimsonRed);
  padding: 10rem 5% 5rem 5%;
  /* text-align: center; */
}
.appbreadcrumb h3 {
  font-size: 5rem;
  color: var(--whiteColor);
  font-weight: 800;
}
.appbreadcrumb h6 {
  font-size: 1.6rem;
  font-weight: 400;
  color: var(--whiteColor);
  width: 60%;
  margin: auto;
}
/* ----------------------------------- */
.privacyContainer h4 {
  font-size: 2.2rem;
  color: var(--blackColor);
}
.privacyContainer p {
  font-size: 1.6rem;
  color: var(--blackColor);
  margin: 0.5rem 0 2rem 0 !important;
}
.privacyContainer p ul {
  padding-left: 18px;
}
.privacyContainer p ul li {
  font-size: 1.5rem;
  margin: 10px 0;
}
.privacyContainer p ul li strong {
  font-size: 1.5rem;
  font-weight: 700;
}
/* ----------------------------------- */
.aboutContent h4,
.aboutContent h4 strong {
  font-size: 4rem;
  font-weight: 800;
}
.aboutContent h4 strong {
  color: var(--crimsonRed);
}
.aboutContent p {
  font-size: 1.4rem;
  margin-top: 2rem !important;
}
.missionContent h4 {
  font-size: 3rem;
  text-transform: capitalize;
}
.missionContent p {
  font-size: 1.4rem;
  margin-top: 1rem !important;
}
.missionContent ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.missionContent ul li {
  font-size: 1.4rem;
  font-weight: 400;
  margin-top: 1rem !important;
}
.missionContent ul li strong {
  font-size: 1.4rem;
  font-weight: 800;
}
/* ----------------------------------- */
.contactContainer {
  border-radius: 1rem;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  overflow: hidden;
}
.contactContainer .formContent {
  background: url("./images/howSection.svg");
  background-size: cover;
  background-position: center;
  padding: 2rem;
}
.contactContainer .formContent h4 {
  font-size: 2.8rem;
  font-weight: 800;
  color: var(--whiteColor);
  margin-bottom: 0.5rem !important;
}
.contactContainer .formContent p {
  font-size: 1.4rem;
  font-weight: 300;
  color: var(--whiteColor);
  margin-bottom: 2rem !important;
}
.contactContainer .formContent .formTxt {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 1rem;
  margin-bottom: 2rem !important;
}
.contactContainer .formContent .formTxt span {
  width: 4rem;
  height: 4rem;
  min-width: 4rem;
  line-height: 4.2rem;
  border-radius: 5rem;
  border: 0.1rem solid var(--whiteColor);
  font-size: 1.6rem;
  text-align: center;
}

.contactContainer .formContent .formTxt span svg {
  font-size: 1.6rem;
  color: var(--whiteColor);
}
.contactContainer .formContent .formTxt p {
  margin-bottom: 0 !important;
  font-size: 1.6rem;
  font-weight: 800;
}
.formWrapper {
  padding: 2rem 0;
}
.formWrapper p {
  font-size: 1.6rem;
  margin-bottom: 1.6rem !important;
  line-height: 2.2rem;
  font-weight: 800;
}
.formWrapper h4 {
  font-size: 2rem;
  font-weight: 800;
  text-transform: capitalize;
  margin-bottom: 2rem !important;
  padding-left: 1.5rem;
}
.formGroup {
  margin-bottom: 2rem;
}
.formBtns {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 1rem;
}
.formBtns .btn {
  border: none;
  padding: 1.4rem 2.6rem;
  cursor: pointer;
  border-radius: 0.6rem;
  font-size: 1.4rem;
  font-weight: 800;
}
.btnActive {
  background-color: var(--crimsonRed);
  color: var(--whiteColor);
}
/* ----------------------------------- */
.modalWrapper {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 9999;
}
.modalWrapper.active {
  display: block;
}
.modalContainer {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modalContent {
  width: 60%;
  background: #fff;
  border-radius: 1rem;
  transition: all 0.3s;
  transform: translateY(20rem);
}
.modalWrapper.active .modalContent {
  transform: translateX(0);
  animation: animateModal 0.3s alternate;
}
@keyframes animateModal {
  from {
    transform: translateY(20rem);
  }
  to {
    transform: translateY(0);
  }
}
.modalHead {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.4rem;
  border-bottom: 0.1rem solid #ccc;
}
.modalHead h4 {
  font-size: 2.2rem;
  font-weight: 800;
}
.modalHead button {
  background: transparent;
  width: 4rem;
  height: 4rem;
  border: 0.1rem solid #ccc;
  border-radius: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
.modalHead button:hover {
  background-color: var(--crimsonRed);
  background-color: var(--crimsonRed);
}
.modalHead button:hover svg path {
  stroke: var(--whiteColor);
}
.modalHead button svg {
  width: 2rem;
  height: 2rem;
}
.Toastify__toast-body > div:last-child {
  word-break: break-word;
  flex: 1 1;
  font-size: 1.4rem;
  color: var(--blackColor);
}
/* ----------------------------------- */
.blogDetails {
  margin-top: 5rem;
}
.blogDetails .blogTitle {
  font-size: 4rem;
  font-weight: 800;
  line-height: 4.4rem;
  margin: 2rem 0 !important;
}
.blogDetails .blogFull {
  width: 100%;
  height: 40rem;
  object-fit: cover;
  border-radius: 1rem;
}
.blogDetails p {
  font-size: 1.6rem;
  font-weight: 500;
  text-align: justify;
  margin-top: 1rem !important;
}
.blogDetails h6 {
  font-size: 2rem;
  margin-top: 3rem !important;
}
.shareIcon {
  display: flex;
  column-gap: 1rem;
  margin: 2rem 0;
}
.modalBody .discoverPicture img {
  width: 75%;
}
.recentBlogHeading {
  font-size: 2.2rem;
  font-weight: 800;
  margin-bottom: 2rem !important;
}
/* ----------------------------------- */
.loadingContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  align-items: center;
  display: flex;
  justify-content: center;
  z-index: 99999;
}
.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
  height: 4rem;
}
.dot {
  background: white;
  margin: 5px;
  animation-name: loader;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}
.loadingContainer h6 {
  font-size: 1.8rem;
  color: var(--whiteColor);
}
.dot:nth-child(1) {
  animation-delay: 0.2s;
}
.dot:nth-child(2) {
  animation-delay: 0.4s;
}
.dot:nth-child(3) {
  animation-delay: 0.6s;
}
.dot:nth-child(4) {
  animation-delay: 0.8s;
}
.dot:nth-child(5) {
  animation-delay: 1s;
}

@keyframes loader {
  from {
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 2rem;
  }
  to {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 2rem;
  }
}
.Toastify__toast-container {
  z-index: 200000;
}

.text-info h2:nth-child(1) {
  color: var(--crimsonRed);
  font-weight: normal;
  padding-top: 1.8rem;
  padding-bottom: 1rem;
}
.text-info h2 {
  font-size: 4.5rem;
  font-weight: bold;
  color: var(--blackColor);
  letter-spacing: 5px;
  text-transform: uppercase;
}

.text-info p {
  color: var(--blackColor);
  font-size: 1.6rem;
  line-height: 24px;
}
.hero-image-desktop {
  display: block;
  height: 55rem;
  width: 100%;
}
.hero-image-desktop img {
  clip-path: polygon(50% 0%, 100% 0, 100% 0, 75% 100%, 0 100%, 0% 60%, 0 0);
  height: 100%;
  object-fit: cover;
  width: 100%;
}
.d-none {
  display: none !important;
}
.pb-0 {
  padding-bottom: 0;
}
.comingSoonSection {
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
}
.privacyContainer h6 {
  font-size: 16px;
  margin: 8px 0 !important;
}

.exchangeSection {
  background: #fff0f3;
}
.exchangeSection .chooseContent {
  padding: 2rem;
  width: 80%;
}
.exchangeSection .chooseContent h4 {
  position: relative;
  font-size: 4rem;
  margin-bottom: 2rem !important;
  color: var(--crimsonRed);
}
.exchangeSection .chooseContent h4::before {
  position: absolute;
  content: "";
  bottom: -1rem;
  left: 0;
  width: 8rem;
  height: 0.3rem;
  background-color: var(--crimsonRed);
  border-radius: 2rem;
}
.exchangeSection .chooseContent p {
  font-size: 1.6rem;
  line-height: 2.6rem;
}
/* ----------------------------------- */

@media only screen and (max-width: 600px) {
  .mobileReverse .discoverContent {
    flex-direction: row-reverse;
    text-align: left;
  }
  .nav-link.registerBtn {
    margin-top: 3rem;
  }
  .mobileMenu {
    background: #ccc;
    border-radius: 5rem;
    display: block;
    height: 4rem;
    line-height: 4rem;
    text-align: center;
    width: 4rem;
  }
  .menuWraper {
    background-color: #fff;
    background-color: var(--whiteColor);
    height: 100vh;
    left: 0;
    position: absolute;
    top: 7.5rem;
    width: 100%;
    z-index: 99;
    display: none;
  }
  .menuWraper.active {
    display: block;
  }
  .container {
    width: 100%;
  }
  .row {
    flex-wrap: wrap;
  }
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-10 {
    width: 100%;
  }

  .navbar {
    padding: 0 1.5rem;
  }
  .linkWrapper {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    list-style: none;
    margin: 0;
    padding: 2rem 2rem;
  }
  .topHeader {
    padding: 1.5rem 0;
    position: fixed;
    z-index: 9999;
    background-color: var(--whiteColor);
    left: 0;
    top: 0;
    width: 100vw;
  }
  .bannerContent h2,
  .bannerContent h2 strong {
    font-size: 3.5rem;
  }
  .bannerBtns a {
    font-size: 1.2rem;
    padding: 1.4rem 1.9rem;
    width: 50%;
    text-align: center;
  }
  .headingWrapper {
    width: 100%;
    margin: 0 auto 5rem auto;
    padding: 0 1.5rem;
  }
  .mainHeading {
    font-size: 2.8rem;
  }
  .mainSubheading {
    font-size: 1.4rem;
  }
  .singleChooseCard {
    margin-bottom: 2rem;
    height: auto !important;
  }
  .howSection .bannerLeft {
    display: none;
  }
  .howSection {
    padding: 5rem 0;
    margin-top: 0;
  }
  .discoverPicture img {
    width: 100%;
    height: auto;
  }
  .appSection .appPicture {
    display: none;
  }
  .linkeContainer {
    width: 100%;
  }
  .linkeContainer h4 {
    font-size: 1.8rem;
  }
  .linkeContainer ul li a {
    font-size: 1.4rem;
  }
  .bannerImg {
    text-align: center;
    margin-top: 8rem;
  }
  .bannerContent {
    margin-top: 7rem;
  }
  .bannerContent p {
    margin: 2rem 0 !important;
  }
  .singleBlogCard {
    width: 90%;
    margin: auto auto 3rem auto;
  }
  .testimonialCard {
    width: 90%;
    margin: 6rem auto 0 auto;
  }
  .testimonialSection {
    padding-bottom: 6rem;
  }
  .footerLeft img {
    width: 68%;
  }
  .logoContainer img {
    width: 17rem;
  }
  .blogDetails .singleBlogCard {
    width: 100%;
  }
  .blogDetails p {
    text-align: left;
  }
  .blogDetails .blogFull {
    width: 100%;
    height: auto;
  }
  .privacyContainer {
    padding: 0 1.5rem;
  }
  .appbreadcrumb h3 {
    font-size: 3rem;
  }
  .appbreadcrumb h6 {
    width: 100%;
    font-size: 1.3rem;
  }
  .formBtns .btn {
    border: none;
    padding: 1.4rem 1.4rem;
    cursor: pointer;
    border-radius: 0.6rem;
    font-size: 1.2rem;
    font-weight: 800;
    width: 50%;
  }
  .modalContent {
    width: 90%;
  }
  .aboutContent h4,
  .aboutContent h4 strong {
    font-size: 3rem;
  }
  .col-md-4 .singleBlogCard {
    width: 100%;
  }
  .modalBody .discoverPicture {
    display: none;
  }
  .blogDetails .blogTitle {
    font-size: 2.5rem;
    line-height: 3.4rem;
  }
  .mobileMenu svg {
    font-size: 1.8rem;
    margin-top: 1.1rem;
  }
  .mobileNone {
    display: none;
  }
  .missionContent h4 {
    font-size: 2.2rem;
  }
  .appbreadcrumb {
    text-align: center;
  }
  .text-info {
    padding: 2rem 0.5rem !important;
  }
  .hero-image-desktop {
    display: none !important;
  }
  .text-info h2 {
    font-size: 3rem;
  }
  .text-info h2:nth-child(1) {
    padding-bottom: 0;
  }
  .exchangeSection .chooseContent {
    padding: 0;
    width: 100%;
  }
  .exchangeSection .chooseContent h4 {
    font-size: 3rem;
  }
  .exchangeSection .chooseContent p {
    font-size: 1.5rem;
    line-height: 2.5rem;
    margin-bottom: 2rem !important;
  }
}

/* ----------------------------- */
